.legend-container {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 1em;
}

.legend-item {
    // display: inline-block;
    position: absolute;
    padding: 1px 8px 2px 2px;
    border-radius: 20px;
    font-size: 0.8rem;
    width: fit-content;
}

.style-pill {
    position: relative;
    justify-content: center !important;
    padding: 0.3em 0.5em !important;
    margin-left: 0.5em !important;
    height: 2em !important;
    width: 2em !important;
}
