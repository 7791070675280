.totals-card-dashboard {
  height: 100%;

  .totals-card-dashboard-header {
    text-align: center;

    b {
      color: white;
    }
  }

  .totals-card-dashboard-body {
    grid-template: 50% 1fr / 50% 1fr;
  }
}
