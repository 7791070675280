@import '../../../../../../shared/design-system/styles/template/variables';
@import '../../../../../../shared/design-system/styles/colors';

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary !important;
  // font-weight: 500;
}

.ant-tabs .ant-tabs-tab:hover {
  color: $primary !important;
}

.ant-tabs-ink-bar {
  background: $primary !important;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.revenue-card {
  height: calc(100vh - 240px);
  overflow: hidden;
}

div.modal-title {
  color: $primary !important;
}
