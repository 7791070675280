@import '../../../../../../shared/design-system/styles/colors';

.section-title {
  font-size: 0.75rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  height: 2rem;
  text-transform: uppercase;
}

.badge-teal {
  background-color: $links-teal !important;
}
