.modal-content {
  border-radius: 4px !important;

  .modal-header {
    padding: 0.5rem 1rem !important;
    background-color: #f3f4f7 !important;
    border-radius: 4px !important;

    b {
      color: $links-blue;
      font-size: 1rem;
      font-weight: 900;
    }
  }
}

.modal-520h {
  min-height: 520px;
}
