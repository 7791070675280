.input-num {
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// input[type='number'] {
//   -moz-appearance: textfield;
// }

.input-num-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  .ant-input-number-handler-wrap {
    display: none;
  }

  input {
    padding-left: 0 !important;
    font-size: medium !important;
  }

  i {
    transition: opacity 0.3s ease;
    opacity: 0;
    position: absolute;
    right: 10px;
  }

  .ant-input-number-affix-wrapper:focus + i,
  .ant-input-number-affix-wrapper:hover + i {
    opacity: 1;
  }
}

.input-num-wrapper {
  max-width: 100%;

  .input-num {
    width: 80%;
    box-sizing: border-box;
  }
}

@media (min-width: 1300px) {
  .td-cdg {
    width: 10em;
  }

  .tr-cdg {
    width: 10em;
  }
}
.hide-spinner::-webkit-inner-spin-button,
.hide-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-spinner {
  -moz-appearance: textfield; /* Firefox */
}

.tr-head {
  height: 83px;
}
