.hit-the-floor {
    color: #fff;
    font-size: 12em;
    font-weight: bold;
    font-family: Helvetica;
    text-shadow:
        0 1px 0 #ccc,
        0 2px 0 #c9c9c9,
        0 3px 0 #bbb,
        0 4px 0 #b9b9b9,
        0 5px 0 #aaa,
        0 3px 5px rgba(0, 0, 0, 0.2),
        0 5px 10px rgba(0, 0, 0, 0.07),
        0 10px 10px rgba(0, 0, 0, 0.01),
        0 20px 20px rgba(0, 0, 0, 0.07);
}

.hit-the-floor {
    text-align: center;
}
