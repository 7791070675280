.dashboard-project-table-container {
  height: calc(100vh - 200px);
  overflow-y: auto;

  thead > tr {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
