@import '../../../../../../shared/design-system/styles/template/variables';
@import '../../../../../../shared/design-system/styles/colors';

#header-buttons {
  .ant-select-selector {
    color: $primary !important;
    border: 1px solid !important;
    border-radius: 0 !important;
  }
}
