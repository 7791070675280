@import "../../styles/template/variables";

.filter-header {
    font-size: 0.8em;
}

label.form-label {
    font-size: 0.9em;
    // font-weight: 500;
    margin-bottom: 0;
}

.trash-btn:focus,
.trash-btn:active {
    border: none !important;
    outline: none !important;
}
.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background-color: $primary !important;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: $primary !important;
}

.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: $primary;
}

.createDateRangePicker
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.createDateRangePicker .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.createDateRangePicker
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.createDateRangePicker
    .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background-color: #abc4ed !important;
}

.createDateRangePicker .ant-btn-primary {
    background-color: $primary !important;
    background-color: $primary !important;
}
.createDateRangePicker .ant-picker-focused {
    border-color: $primary;
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: $primary !important;
}
.ant-switch.ant-switch-checked {
    background: $primary !important;
}
.form-control::placeholder {
    color: #bebebe;
    opacity: 1;
}
.ant-picker-active-bar {
    border: 1px solid $primary !important;
}
