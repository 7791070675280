.order-status {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1rem;

  &.success {
    background-color: green;
  }

  &.danger {
    background-color: red;
  }
}
