@import '../../../../../../shared/design-system/styles/template/variables';
@import '../../../../../../shared/design-system/styles/colors';

.overflow-visible {
  overflow-x: visible !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary;
  border-color: $primary;
}

div.modal-title {
  color: $primary !important;
}

.border-none {
  border: transparent !important;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #2d61b4;
}

:where(.css-dev-only-do-not-override-1uweeqc).ant-switch.ant-switch-checked {
  background: #22519e;
}

.createDateRangePickerr .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: $primary !important;
}

.createDateRangePickerr .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $primary !important;
}

.createDateRangePickerr .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  background-color: $primary !important;
}

.createDateRangePickerr .ant-btn-primary {
  background-color: $primary !important;
  background-color: $primary !important;
}

.container-table {
  height: auto;
  scrollbar-width: none;
}
