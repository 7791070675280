// Grayscale Text Utilities

.text-xs {
  font-size: 0.7rem !important;
}

.text-sm {
  font-size: 0.9rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.2rem !important;
}

.text-xl {
  font-size: 1.5rem !important;
}

.text-2xl {
  font-size: 2rem !important;
}

.text-gray-100 {
  color: $gray-100 !important;
}

.text-gray-200 {
  color: $gray-200 !important;
}

.text-gray-300 {
  color: $gray-300 !important;
}

.text-gray-400 {
  color: $gray-400 !important;
}

.text-gray-500 {
  color: $gray-500 !important;
}

.text-gray-600 {
  color: $gray-600 !important;
}

.text-gray-700 {
  color: $gray-700 !important;
}

.text-gray-800 {
  color: $gray-800 !important;
}

.text-gray-900 {
  color: $gray-900 !important;
}

.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-blue-links {
  color: $primary !important;
}

.text-teal-links {
  color: $links-teal !important;
}

.text-gray-links {
  color: $links-dark-gray !important;
}

.text-placeholder {
  color: $links-placeholder !important;
}

.text-gray {
  color: gray;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}
