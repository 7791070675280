@import "../../styles/template/_variables.scss";

.price_Ranges {
    float: right;
    width: 50%;
}

.price_Ranges {
    margin-top: 1em;
    display: block;
    text-align: center;
    padding: 6px 0 6px 0;
    color: #6f6e6e;
    font-weight: 500;
}

.price_Ranges .max_value {
    padding-right: 12px;
    padding-left: 12px;
}

.price_Ranges .min_value {
    padding-right: 22px;
    padding-left: 12px;
    // border-right: 1px solid #dadada;
}


.price_Ranges .disabled {
    pointer-events: none;
    cursor: default;
    color: #e5e4e2;
}


.price_Ranges :hover {
    background: $blue;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
}


.btnClear {
    margin-bottom: -0.5em;
    clear: both;
    border-top: 1px solid #dadada;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
}

div.min_value:active {
    background-color: $blue !important;
}

div.min_value.active {
    background-color: $blue !important;
    color: white;
}

div.max_value.active {
    background-color: $blue !important;
    color: white;
}

a.dropdown-item.prangeB:hover {
    background-color: transparent !important;
}

input.inputError,
input.inputError:focus {
    border-color: #e2231a;
    background-color: white;
    color: #e2231a;
    box-shadow: inset 0 0 5px #f7bdbb;
    border-radius: 0;
}

.dropdown-menu {
    font-size: 1em !important;
    width: 26em;
    max-height: 26em;
    border-radius: 0px;
    overflow-y: auto;
    overflow-x: none;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background: #888888;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.price_Ranges {
    margin-top: 2em;
    float: right;
    width: 50%;
    overflow-y: auto;
    max-height: 13em;
}

.price_Ranges .price_Ranges {
    width: 100%;
}

.ox-none {
    overflow-x: hidden !important;
}

#header {
    position: absolute !important;
    width: 50%;
    height: 13%;
    background-color: white;
    padding-top: 0.45em;
    margin-top: 0;
    top: 0;
    border-bottom: 1px solid #dadada;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

#header:hover {
    color: black;
}

.border-r {
    border: 1px solid lightgray !important;
    border-radius: 8px !important;
}

// .active.dropdown-item {
//     background-color: $primary !important;
// }
// .dropdown-item:hover {
//     background-color: rgb(149, 179, 250) !important;
// }