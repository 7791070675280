@each $color, $value in $theme-colors {
  @each $position in [ 'left', 'bottom'] {
    .border-#{$position}-#{$color} {
      border-#{$position}: 0.25rem solid $value !important;
    }
  }
}

.vertical-divider {
  border-right: 1px solid #c7c8c9;
}
