.overbooking-table {
  #fte,
  #md,
  #vacation,
  #other {
    min-width: 70px;
  }

  #sick-leave {
    min-width: 85px;
  }

  #fte,
  #md {
    background-color: #df8a90;
  }

  #fte-cell,
  #md-cell {
    background-color: #dfb2b5;
  }
}
