@import './colors';
@import './typography';
@import './template/sd-admin-2.scss';

* {
  font-family: 'Nunito';
  // scrollbar-width: thin;
}

.span-values {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-bottom: 16px;
  div {
    &.head {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: $links-dark-gray;
    }
    &.value {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.25px;
      color: $links-dark-gray;
      margin-top: 6px;
    }
    .mid-value {
      font-size: 18px;
      font-weight: 600;
    }
    .big-value {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .biggest-value {
      font-size: 24px;
      font-weight: 800;
    }
  }
}
