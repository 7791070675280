@import "../../styles/template/variables.scss";

.app-header {
  position: relative;
  padding: 0.5rem 1rem;
}

.corporate-img {
  height: 50px;
}
// .icon-circle {
// 	width: 40px;
// 	height: 40px;
// 	border-radius: 50%;
// 	background-color: whitesmoke;
// 	justify-content: center;
// 	align-items: center;
// }
.role-manager {
  display: block;
  font-size: 0.8em;
  margin-top: 0px;
}

.link {
  text-decoration: none !important;
}
