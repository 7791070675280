.totals-card {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
}

// .piede-tab td:first-child {
//   min-width: 580px;
// }

// .piede-tab td {
//   min-width: 190px;
// }

// .piede-tab td.work-in-progress {
//   min-width: 130px;
// }
.drop-width {
  max-width: 14em;
  max-height: 18em;
}
