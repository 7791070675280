@import "../../styles/template/variables";
@import "../../styles/colors";

.btn {
  min-height: 2rem !important;
  position: relative;
  will-change: transform;
  border-radius: 2px !important;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
  $danger: #dc3545; // Custom Button
  &.btn-links {
    background: $primary !important;
    color: $white !important;
    border: none !important;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &.btn-links-outline {
    color: $primary !important;
    border-color: $primary !important;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  &.btn-links-danger-outline {
    color: $danger !important;
    border-color: $danger !important;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  &.btn-links-pagination {
    color: $primary !important;
    border-color: $primary !important;
  }

  &.btn-links-pagination:hover {
    color: white !important;
    border-color: $primary !important;
    background-color: $primary !important;
  }

  &.btn-links-outline:hover {
    color: white !important;
    border-color: $primary !important;
    background-color: $primary !important;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  &.btn-links-danger-outline:hover {
    color: white !important;
    border-color: $danger !important;
    background-color: $danger !important;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &.btn-teal {
    background: $links-teal !important;
    color: $white !important;
    border: none !important;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &.btn-links-secondary {
    background-color: transparent !important;
    color: $primary;
    border: 2px solid;
    padding-left: 2rem;
    padding-right: 2rem;

    &.mobile {
      color: $white;
      border-color: $white;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &.btn-links-no-border {
    background: transparent;
    color: $primary;
    border: none;
    padding-left: 2rem;
    padding-right: 2rem;

    &:hover {
      color: $primary;
    }
  }

  // Icons

  i:not(:first-child),
  svg:not(:first-child) {
    margin-left: 0.5rem;
  }

  i:not(:last-child),
  svg:not(:last-child) {
    margin-right: 0.5rem;
  }
}

// Remove translateY and margin animation when btn is included in a btn-group or input-group

.btn-group,
.input-group {
  .btn {
    margin-right: 0;
    transform: translateY(0);
  }
}

.btn-inner--icon {
  i:not(.fa) {
    position: relative;
    top: 2px;
  }
}

.btn-link {
  // font-weight: $btn-font-weight;
  box-shadow: none;

  &:hover {
    background-color: inherit;
    box-shadow: none;
    transform: none;
  }
}

.btn-icon {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}

.btn-neutral {
  color: theme-color("primary");
}

.btn:active,
.btn:focus {
  border-color: $primary !important;
}
