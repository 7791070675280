footer.sticky-footer {
  padding-bottom: 1rem;
  flex-shrink: 0;
  .copyright {
    line-height: 1;
    font-size: 0.8rem;
  }
}

body.sidebar-toggled {
  footer.sticky-footer {
    width: 100%;
  }
}

footer {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-top: 1rem;
  color: $gray-600;
}
