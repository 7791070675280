@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-ExtraBold.ttf');
  font-weight: 800;
}
@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-Black.ttf');
  font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
  color: $links-dark-gray !important;
}
.fs-7 {
  font-size: 14px;
}
.fs-8 {
  font-size: 13px;
}

p {
  color: $links-dark-gray;
}

input {
  font-family: inherit !important;
}
