@import "../../styles/template/variables";

.note-input-container {
    display: column;
    justify-content: space-between;
}

.note-input {
    flex-grow: 1;
    margin-right: 0rem;
    padding: 0.5rem;
}

.note-card {
    background-color: white;
    margin-top: 0rem;
    padding: 0.2rem;
    padding-left: 0.4rem;
    border-radius: 0.3rem;
    position: relative;
    margin-left: 2.5em;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    height: fit-content;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.082);
}

.note-card p {
    margin: 0;
    padding: 0;
}

.note-card-other-user {
    background-color: #a4c8ff14;
    margin-top: 0rem;
    padding: 0.2rem;
    padding-left: 0.4rem;
    border-radius: 0.3rem;
    position: relative;
    margin-left: 1.5em;
    height: fit-content;
    box-shadow: 0 2px 5px rgb(0 0 0 / 0.082);
}

.note-card-other-user p {
    margin: 0;
    padding: 0;
}

.no-notes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        margin-top: 1rem;
    }
}

.wrapper-editor {
    position: relative;
}

//variables
$editor-width: auto;

.div-buttons {
    background-color: transparent !important;
    border: 1px solid lightgray;
    border-top: 0;
    position: relative;
    width: $editor-width;
}

.editor {
    width: $editor-width;
}

.bottom-card-note {
}

.div-left-container {
    border: 1px solid whitesmoke;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: -1em;
    height: 50vh;
}

.div-right-container {
    display: flex;
    position: relative;
    overflow-y: none;
    overflow-x: none;
    flex-direction: column;
    height: 51vh;
}

.isEditing {
    transition: box-shadow 1s;
    box-shadow: inset 0 0px 10px rgb(0 0 0 / 0.15);
}

.ql-toolbar .ql-stroke {
    fill: none !important;
    stroke: $blue !important;
    background-color: red !important;
}

.ql-toolbar .ql-fill {
    fill: none !important;
    stroke: $blue !important;
}

.ql-toolbar .ql-picker {
    stroke: $blue !important;
}

.ql-toolbar.ql-snow {
    background-color: #a4c8ff14 !important;
}

.username {
    font-size: 0.8em;
}

.content-text {
    font-size: 0.8em;
}

.profile-img-note {
    border-radius: 20%;
    overflow: hidden;
    background-color: #cccccc;
    align-items: center !important;
    justify-content: center;
    text-align: center;
    margin: auto;
    width: 1.6em;
    height: 1.6em;
}

.btn-cancel-note {
    right: 0;
    bottom: 0.1em;
}

.btn-edit-note {
    position: absolute;
    right: 0em;
    top: 0;
}

.btn-edit-note i {
    color: $blue;
    font-size: 0.9em;
}

.date-note {
    font-size: 0.7em;
    margin-bottom: 0.3em;
}

// @media (min-width: 1920px) {
//     .screen-note {
//         max-height: 32em;
//     }
// }
// @media (max-width: 1800px) {
//     .screen-note {
//         max-height: 25em !important;
//     }
// }

.note-icon {
    font-size: 8em;
    color: #a4c8ff58;
}

//////////////////////////////
.innerHtml p {
    clear: both;
}
