/* Import Material Symbols Outlined font */
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

/* Import Material Symbols Rounded font */
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0');
@import '../../styles/template/variables';

.chatbot-toggler {
  position: fixed;
  bottom: 10px;
  right: 30px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1), 0 32px 64px -48px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.2s ease;

  .animation-container {
    pointer-events: none;
  }
}

body.show-chatbot .chatbot-toggler {
  transform: rotate(90deg);
  background: $primary;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.chatbot-toggler span {
  color: #fff;
  position: absolute;
  font-size: 1.2rem;
  font-weight: 600;
  transition: all 0.2s ease;
}

.chatbot-toggler span:last-child,
body.show-chatbot .chatbot-toggler span:first-child {
  opacity: 0;
}

body.show-chatbot .chatbot-toggler span:last-child {
  opacity: 1;
}

.chatbot {
  position: fixed;
  right: 70px;
  bottom: 60px;
  overflow: hidden;

  transform: scale(0.1);
  opacity: 0;
  pointer-events: none;
  background: #fff;
  transform-origin: bottom right;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  textarea {
    height: fit-content;
  }
  p {
    color: inherit;
  }
}

body.show-chatbot .chatbot {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
}

.nlux-theme-nova[data-color-scheme='light'] {
  --nlux-HumanMessage--BackgroundColor: #22519e !important;
  --nlux-ChatRoom--BorderColor: #22519e !important;
  --nlux-ChatItem-Avatar--BorderWidth: 0 !important;
  --nlux-ChatItem-Message-BubbleLayout--BorderWidth: 0 !important;
}

.nlux-comp-chatItem.nlux-comp-chatItem--sent
  > .nlux-comp-chatItem-participantInfo
  > .nlux-comp-avatar
  > .nlux-comp-avatarContainer
  > .nlux-comp-avatarPicture {
  background-color: transparent !important;
}

.nlux-comp-avatar {
  border-radius: 0;
}
